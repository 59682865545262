import React from 'react'
import BlockContent from '@sanity/block-content-to-react'
import { serializers } from '@lib/serializers'
import Img from 'next/image'

import VideoLoop from '@components/video-loop'
import { useNextSanityImage } from 'next-sanity-image'
import { getSanityClient } from '@lib/sanity'

const Hero = ({ data = {} }) => {
  const { content, bgType, photos, video } = data

  return (
    <section className="hero">
      {content && (
        <div className="hero--overlay">
          <div className="hero--content">
            <BlockContent
              renderContainerOnSingleChild
              className="rc"
              blocks={content}
              serializers={serializers}
            />
          </div>
        </div>
      )}

      {bgType === 'video' && (
        <>
          <div className="hero--bg is-desktop">
            <VideoLoop title={video.title} id={video.id} />
          </div>
          <div className="hero--bg is-mobile">
            <VideoLoop title={video.title} id={video.id} />
          </div>
        </>
      )}

      {bgType === 'photo' && (
        <>
          {photos?.mobilePhoto?.asset ? (
            <Img
              {...useNextSanityImage(getSanityClient(), photos.mobilePhoto)}
              layout="fill"
              objectFit="cover"
            />
          ) : (
            <Img
              {...useNextSanityImage(getSanityClient(), photos?.desktopPhoto)}
              layout="fill"
              objectFit="cover"
            />
          )}
        </>
      )}
    </section>
  )
}

export default Hero
